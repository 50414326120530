<template>
	<div class="server_box">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
			<el-breadcrumb-item>业务中心</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="server-box">
			<!-- <div class="top">
                <el-alert title="特别提示：用户自身管理后台新增服务器，所有权归属自己；新增推荐用户服务器，请退出当前账号使用推荐用户的账号登陆操作" type="warning" :closable="false"></el-alert>
            </div> -->
			<div class="middle">
				<el-button type="primary" @click="goBuy">购买</el-button>
			</div>
			<div class="bottom">
				<el-table :data="tableData" style="width: 90%;margin:55px 50px">
					<el-table-column prop="ppsOrder" label="PPS数量" align="center"></el-table-column>
					<el-table-column prop="id" label="订单编号" align="center"></el-table-column>
					<el-table-column label="托管时间(月)" align="center">
						<template slot-scope="scope">
							<span>({{scope.row.trusteeshipFeeMonth}}/{{scope.row.trusteeshipMonth}})</span>
						</template>
					</el-table-column>
					<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
					<el-table-column label="交付时间" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status==10">{{scope.row.deliveryDays}}</span>
						</template>
					</el-table-column>
					<el-table-column label="有效期" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status==10">{{scope.row.ppsValidite}}</span>
						</template>
					</el-table-column>
					<el-table-column label="订单状态" align="center">
						<template slot-scope="scope">
							<span style="color: #84CE61;" v-if="scope.row.status==10">审核通过</span>
							<span style="color: #1989FA;" v-if="scope.row.status==1">待审核</span>
							<span style="color: #FF8C8D;" v-if="scope.row.status==0">未支付</span>
							<span style="color: #1989FA;" v-if="scope.row.status==-1">用户取消</span>
							<span style="color: #FF8C8D;" v-if="scope.row.status==-10">审核不通过</span>
						</template>
					</el-table-column>
					<el-table-column label="审核时间" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status==-10 || scope.row.status==10">{{scope.row.auditAt}}</span>
						</template>
					</el-table-column>
					<el-table-column label="审核结果" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.status==-10">{{scope.row.auditReason}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" @click="goServer(scope.row)">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block">
					<el-pagination @current-change="handleCurrentChange1" layout="prev, pager, next, jumper"
						:page-size="page.pagesize" :current-page="page.currentPage" :total="page.total">
					</el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		Server
	} from "@/api/index";
	export default {
		data() {
			return {
				tableData: [],
				page: { //服务器
					total: 0,
					pagesize: 10,
					currentPage: 1,
				},
			};
		},
		created() {
			this.Userlist()
		},
		methods: {
			//购买
			goServer(item) {
				this.$router.push({
					path: '/server/detail',
					query: {
						id: item.id
					}
				})
			},
			//分页
			async handleCurrentChange1(val) {
				this.page.currentPage = val;
				this.Userlist(this.page.currentPage)
			},
			async Userlist(page) {
				let {
					status,
					data
				} = await Server.userlist({
					PageNum: page
				});
				if (status) {
					if(data.code == 0){
						this.tableData = data.data.list;
						//服务器列表分页
						this.page.total = data.data.total;
						this.page.currentPage = data.data.currentPage;
					}
					
				}
			},
			goBuy() {
				this.$router.push('/server/purchase')
			},
			goPay(kuang_id) {
				this.$router.push({
					path: "/upload?kuang=" + kuang_id,
					params: {
						kuang: kuang_id
					}
				})
			},
		}
	};
</script>

<style lang="less">
	.server_box {
		body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}

		.el-popover__reference-wrapper {
			.el-button--danger {
				margin-left: 10px;
			}
		}

		.el-table::before {
			height: 0px;
			width: 0px;
		}

		.el-table th {
			background-color: #F5F7FA;
		}

		.el-breadcrumb {
			padding: 30px;

			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}

			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}

		.server-box {
			margin: 0 20px;
			background-color: #ffffff;

			.top {
				height: 93px;
				background-color: #fff;
				display: flex;
				align-items: center;
				margin: 0 25px;

				.el-alert--warning.is-light {
					height: 48px;
				}
			}

			.middle {
				padding: 30px 30px 0 55px;
				box-sizing: border-box;
			}

			.bottom {
				position: relative;

				.el-pagination {
					position: absolute;
					right: 110px;
					bottom: 20px;
				}

			}

			.block {
				height: 50px;
			}
		}
	}
</style>
